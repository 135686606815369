import {
  Container,
  Wrap,
  MainTypo,
  SubTypo,
  ServiceTypo,
  CardWrap,
  Card,
  CardMainTypo,
  CardSubTypo,
  ImageDiv,
} from "./Styles";
import { ConLeft, ConRight } from "components";
export const Construction = () => {
  return (
    <Container data-test="con">
      <Wrap>
        <div>
          <MainTypo>
            <span>전문건설사 파트너</span>는<br /> 어떤 일을 하나요?
          </MainTypo>
          <SubTypo>
            공개경쟁 입찰로 프로젝트를 수주하고
            <br />
            공사관리 시스템으로 편하게 관리할 수 있도록 서비스 준비 중입니다.
          </SubTypo>
        </div>
        <CardWrap>
          <Card>
            <ImageDiv>
              <ConLeft />
            </ImageDiv>
            <CardMainTypo>입찰 서비스</CardMainTypo>
            <CardSubTypo>
              공정한 입찰 서비스로 프로젝트를 수주하고
              <br />
              하도급 계약을 진행해요.
            </CardSubTypo>
          </Card>
          <Card>
            <ImageDiv>
              <ConRight />
            </ImageDiv>
            <CardMainTypo>다양한 프로젝트</CardMainTypo>
            <CardSubTypo>
              평당이 아닌 부위별로
              <br />
              상세하게 산출 된 수량으로 발주해요.
            </CardSubTypo>
          </Card>
        </CardWrap>
        <ServiceTypo>*서비스 준비중이에요.</ServiceTypo>
      </Wrap>
    </Container>
  );
};
