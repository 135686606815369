import { colors, theme } from "@builderhub/mui-theme";
import { Box, Typography, styled } from "@mui/material";

export const Container = styled(Box)(() => ({
  width: "100%",
  background: "#FBFAFF",
  minHeight: 768,
  display: "flex",
  justifyContent: "center",
  minWidth: 360,
}));
export const Wrap = styled(Box)(() => ({
  maxWidth: 1200,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "128px 0px",
  [theme.breakpoints.down("md")]: {
    padding: "80px 60px",
  },
}));
export const MainTypo = styled(Typography)(() => ({
  fontSize: 28,
  fontWeight: 400,
  color: colors.gray._60,
  letterSpacing: -0.3,
  whiteSpace: "nowrap",
  span: {
    fontWeight: 700,
    color: colors.purple._60,
  },
  br: {
    display: "none",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
    fontSize: 20,
    br: {
      display: "flex",
    },
  },
}));

export const SubTypo = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: colors.gray._60,
  letterSpacing: -0.3,
  whiteSpace: "nowrap",
  textAlign: "center",
  marginTop: 16,
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));
export const ServiceTypo = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: -0.3,
  whiteSpace: "nowrap",
  color: colors.purple._60,
  textAlign: "center",
  marginTop: 32,
  [theme.breakpoints.down("md")]: {
    height: 14,
    fontSize: 10,
  },
}));

export const CardWrap = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  maxWidth: 936,
  width: "100%",
  padding: "66px 122px 0px 122px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 456,
    marginTop: 32,
    padding: 0,
  },
}));
export const Card = styled(Box)(() => ({
  background: colors.white,
  border: `2px solid ${colors.purple._05}`,
  borderRadius: 12,
  maxWidth: 338,
  maxHeight: 300,
  width: "100%",
  height: "100%",
  padding: "52px 0px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    maxWidth: 240,
    maxHeight: 220,
  },
}));

export const CardMainTypo = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 500,
  color: colors.gray._100,
  letterSpacing: -0.3,
  marginTop: 24,
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
    marginTop: 16,
  },
}));
export const CardSubTypo = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: colors.gray._40,
  letterSpacing: -0.3,
  marginTop: 8,
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

export const ImageDiv = styled(Box)(() => ({
  img: {
    width: 100,
    heigh: 100,
  },
  [theme.breakpoints.down("md")]: {
    img: {
      width: 80,
      height: 80,
    },
  },
}));
