import React from "react";
import {
  MainWrap,
  Container,
  LeftDiv,
  ContentDiv,
  OpenTypo,
  SubTypo,
  LeftImage,
  RightImage,
  RegBtn,
} from "./Styles";
import Image from "next/image";
import imageLoader from "utils/imageLoader";

export function Main(props: { goRegist: () => void }) {
  const { goRegist } = props;
  return (
    <MainWrap>
      <Container>
        <LeftImage>
          <Image
            src="/Main/Img-Left.png"
            alt="MainImage"
            width={136}
            height={140}
            loader={imageLoader}
            //quality={100}}
          />
        </LeftImage>
        <LeftDiv>
          <ContentDiv>
            <div>
              <OpenTypo>파트너스 OPEN!</OpenTypo>
              <SubTypo>
                빌더허브의
                <br />
                파트너가 되어주세요!
              </SubTypo>
            </div>
            <RegBtn variant="contained" onClick={goRegist}>
              파트너 등록하기
            </RegBtn>
          </ContentDiv>
        </LeftDiv>
        <RightImage>
          <Image
            src="/Main/Img-Right.png"
            alt="MainImage"
            width={456}
            height={352}
            priority
            loader={imageLoader}
            //quality={100}}
          />
        </RightImage>
      </Container>
    </MainWrap>
  );
}
