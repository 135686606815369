import { A1, A2, A3, A4, VerticalDotIcon } from "components";
import {
  ArchitectContainer,
  Wrap,
  MainTypo,
  SubTypo,
  ServiceTypo,
  WorkWrap,
  WorkDiv,
  IconDiv,
  DotIconDiv,
  SubTypoDiv,
} from "../Styles";
import { PartnerStart, Service } from "../Common";
export const Architect = () => {
  return (
    <>
      <ArchitectContainer
        sx={{ backgroundColor: "#FBFAFF" }}
        data-test="architect"
      >
        <Wrap>
          <div>
            <MainTypo>
              <span>건축설계사 파트너</span>는<br /> 어떤 일을 하나요?
            </MainTypo>
            <SubTypo>
              빌더허브를 통해 다양한 프로젝트를 수주하고,
              <br />
              많은 사람들에게 프로젝트 포트폴리오를 홍보할 수 있어요.
            </SubTypo>
          </div>
          <WorkWrap>
            <WorkDiv
              gridTemplateColumns={`repeat(2, 1fr)`}
              gridTemplateAreas={`"first1 first2"
             "second1 second2"`}
            >
              <IconDiv sx={{ gridArea: "first1" }}>
                <A1 />
                <span>직접 모델링하기</span>
              </IconDiv>
              <IconDiv sx={{ gridArea: "second1" }}>
                <A2 />
                <span>입찰 서비스</span>
                <DotIconDiv>
                  <VerticalDotIcon />
                  <SubTypoDiv>프로젝트 수주</SubTypoDiv>
                </DotIconDiv>
              </IconDiv>
              <IconDiv sx={{ gridArea: "second2" }}>
                <A3 />
                <span>포트폴리오</span>
                <DotIconDiv>
                  <VerticalDotIcon />
                  <SubTypoDiv>홍보 창구</SubTypoDiv>
                </DotIconDiv>
              </IconDiv>
              <IconDiv sx={{ gridArea: "first2" }}>
                <A4 />
                <span>모델링 서비스</span>
              </IconDiv>
            </WorkDiv>
            <ServiceTypo>*서비스 준비중이에요.</ServiceTypo>
          </WorkWrap>
        </Wrap>
      </ArchitectContainer>
      <PartnerStart />
      <Service />
    </>
  );
};
