import { Container, Wrap, PartnerTypo, BannerWrap } from "./styles";
import Image from "next/image";
import imageLoader from "utils/imageLoader";

export const Partners = () => {
  return (
    <Container>
      {/* <Wrap> */}
      {/* <PartnerTypo>빌더허브 파트너스</PartnerTypo> */}
      <BannerWrap>
        <Image
          src={"/Main/partners/hcore.jpg"}
          width={320}
          height={112}
          alt="hcore"
          loader={imageLoader}
          onClick={() =>
            window.open("https://hcorestore.hyundai-steel.com/", "_blank")
          }
        />
        {/* <Image
            src={"/main/banner.png"}
            width={320}
            height={112}
            alt="banner"
          />
          <Image
            src={"/main/banner.png"}
            width={320}
            height={112}
            alt="banner"
          /> */}
      </BannerWrap>
      {/* </Wrap> */}
    </Container>
  );
};
