import React from "react";
import { Wrap, TabBox, TabWrap, StyledTab, StyledTabs } from "./Styles";
import { Main } from "./Main";
import { Footer } from "./Footer";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import { HiddenAnchor } from "components";
import { useAppSelector } from "store";

export enum Partners {
  architect = "architect",
  generalConstruction = "general-construction",
  construction = "construction",
  modeler = "modeler",
  supplier = "supplier",
}

interface PartnerLayoutProps {
  partner: Partners;
  children: React.ReactNode;
}

export function PartnerLayout(props: PartnerLayoutProps) {
  const { partner, children } = props;
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { authenticated } = useAppSelector((state) => state.auth);
  const handleChange = (_event: React.SyntheticEvent, newValue: Partners) => {
    router.push({ pathname: newValue }, undefined, { scroll: false });
  };
  const goRegist = () => {
    router.push("partner-registration");
  };
  const handleClick = (
    type:
      | "type1"
      | "type2"
      | "homepage"
      | "youtube"
      | "copy"
      | "instagram"
      | "notification"
      | "notice"
      | "inquiry",
    authenticated?: boolean,
  ) => {
    switch (type) {
      case "type1":
        window.open(
          "https://page.builderhub.io/43226ec11ece414b92b12e3aac8e51ef",
        );
        break;
      case "type2":
        window.open(
          "https://page.builderhub.io/7d3f609528594c869cf2204cf2581cab",
        );
        break;
      case "homepage":
        window.open("http://chang-soft.com/");
        break;
      case "youtube":
        window.open("https://www.youtube.com/@user-oo6qk8og1h/featured");
        break;
      case "copy":
        navigator.clipboard.writeText(process.env.NEXT_PUBLIC_HOST_URL);
        getSnackbar("주소가 복사되었습니다.", "success");
        break;
      case "instagram":
        window.open("https://www.instagram.com/builderhub_official/");
        break;
      case "notification":
        window.open(
          `${process.env.NEXT_PUBLIC_HOME_URL}/content/30d30eb37e2043b28ba8ebcd37612362`,
        );
        break;
      case "notice":
        window.location.assign(
          `${process.env.NEXT_PUBLIC_HOME_URL}/help/notice`,
        );
        break;
      case "inquiry":
        if (authenticated)
          window.location.assign(
            `${process.env.NEXT_PUBLIC_HOME_URL}/help/inquiry`,
          );
        else {
          window.location.assign(
            `${process.env.NEXT_PUBLIC_AUTH_URL}/signin?forward=${process.env.NEXT_PUBLIC_HOME_URL}/help/inquiry`,
          );
        }
        break;
    }
  };
  const handleScroll = React.useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    const elem = document.getElementById("tabDiv");
    elem?.scrollIntoView({
      behavior: "smooth",
    });
  }, []);
  const getSnackbar = React.useCallback(
    (errorString: string, variant: "success" | "warning" | "error") => {
      enqueueSnackbar(`${errorString}`, {
        variant: variant,
        autoHideDuration: 2500,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    },
    [enqueueSnackbar],
  );
  return (
    <Wrap>
      <Main goRegist={goRegist} />
      <HiddenAnchor href="/architect">건축설계사</HiddenAnchor>
      <HiddenAnchor href="/general-construction">종합건설사</HiddenAnchor>
      <HiddenAnchor href="/construction">전문건설사</HiddenAnchor>
      <HiddenAnchor href="/modeler">모델링 전문가</HiddenAnchor>
      <HiddenAnchor href="/supplier">자재공급사</HiddenAnchor>
      <HiddenAnchor href="/dashboard">대시보드</HiddenAnchor>
      <TabBox id="tabDiv">
        <TabWrap>
          <StyledTabs
            value={partner}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
          >
            <StyledTab
              disableRipple
              label="건축설계사"
              data-test="architect-tab"
              value={Partners.architect}
              onClick={handleScroll}
            />
            <StyledTab
              disableRipple
              label="종합건설사"
              data-test="genCon-tab"
              value={Partners.generalConstruction}
              onClick={handleScroll}
            />
            <StyledTab
              disableRipple
              label="전문건설사"
              data-test="con-tab"
              value={Partners.construction}
              onClick={handleScroll}
            />
            <StyledTab
              disableRipple
              label="모델링 전문가"
              data-test="modeler-tab"
              value={Partners.modeler}
              onClick={handleScroll}
            />
            <StyledTab
              disableRipple
              label="자재공급사"
              data-test="supplier-tab"
              value={Partners.supplier}
              onClick={handleScroll}
            />
            {/* <StyledTab
              disableRipple
              label="감리 업체"
              data-test="supervision-tab"
            /> */}
          </StyledTabs>
        </TabWrap>
        {children}
      </TabBox>
      <Footer handleClick={handleClick} authenticated={authenticated} />
    </Wrap>
  );
}
