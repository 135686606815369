import {
  Container,
  Wrap,
  MainTypo,
  SubTypo,
  ServiceTypo,
  CardWrap,
  CustomCard,
  CustomCardActionArea,
  CardMainTypo,
  CardSubTypo,
  ImageDiv,
} from "./Styles";
import {
  SupLeft,
  SupRight,
  StructuralSupervisionIcon,
  ConstructionSupervisionIcon,
} from "components";
import { PartnerStart, Service } from "../Common";
import { useRouter } from "next/router";

export const Supervision = () => {
  const router = useRouter();
  return (
    <Container data-test="supervision">
      <Wrap>
        <div>
          <MainTypo>
            <span>감리업체 파트너</span>
            는<br /> 어떤 일을 하나요?
          </MainTypo>
          <SubTypo>
            빌더허브 플랫폼에서 프로젝트와 연계하면,
            <br />
            더욱 쉽게 관리감독을 할 수 있어요.
          </SubTypo>
        </div>
        <CardWrap>
          <CustomCard
            variant="outlined"
            onClick={() => {
              router.replace("https://supervision.builderhub.io");
            }}
          >
            <CustomCardActionArea>
              <ImageDiv>
                <ConstructionSupervisionIcon />
              </ImageDiv>
              <CardMainTypo>시공감리</CardMainTypo>
              <CardSubTypo>
                {/* 빌더허브에서 자재 공급망을
              <br />
              쉽게 확보해 보세요. */}
              </CardSubTypo>
            </CustomCardActionArea>
          </CustomCard>
          <CustomCard
            variant="outlined"
            onClick={() => {
              router.replace("https://supervision.builderhub.io");
            }}
          >
            <CustomCardActionArea>
              <ImageDiv>
                <StructuralSupervisionIcon />
              </ImageDiv>
              <CardMainTypo>구조감리</CardMainTypo>
              <CardSubTypo>
                {/* 자재 공급을 빌더허브 내에서
              <br />
              온라인으로 진행해요! */}
              </CardSubTypo>
            </CustomCardActionArea>
          </CustomCard>
        </CardWrap>
        <ServiceTypo>*서비스 준비중이에요.</ServiceTypo>
      </Wrap>
      <PartnerStart />
      <Service />
    </Container>
  );
};
