import {
  Container,
  Wrap,
  MainTypo,
  SubTypo,
  ServiceTypo,
  CardWrap,
  Card,
  CardMainTypo,
  CardSubTypo,
  ImageDiv,
} from "./styles";
import { Partners } from "./Partners";
import Image from "next/image";
export const Supplier = () => {
  return (
    <Container data-test="supplier">
      <Wrap>
        <div>
          <MainTypo>
            <span>자재공급사 파트너</span>
            는<br /> 어떤 일을 하나요?
          </MainTypo>
          <SubTypo>
            빌더허브 플랫폼에 자재 스토어를 개설하고 프로젝트와 연계하면,
            <br />
            자재 공급망을 쉽게 확보할 수 있어요.
          </SubTypo>
        </div>
        <CardWrap>
          <Card>
            <ImageDiv>
              <Image
                src="/Main/Supplier/supLeft.png"
                alt="supplierLeft"
                width={100}
                height={100}
              />
            </ImageDiv>
            <CardMainTypo>스토어 개설</CardMainTypo>
            <CardSubTypo>
              빌더허브에서 자재 공급망을
              <br />
              쉽게 확보해 보세요.
            </CardSubTypo>
          </Card>
          <Card>
            <ImageDiv>
              <Image
                src="/Main/Supplier/supRight.png"
                alt="supplierRight"
                width={100}
                height={100}
              />
            </ImageDiv>
            <CardMainTypo>시간&비용 절약</CardMainTypo>
            <CardSubTypo>
              자재 공급을 빌더허브 내에서
              <br />
              온라인으로 진행해요!
            </CardSubTypo>
          </Card>
        </CardWrap>
        <ServiceTypo>*서비스 준비중이에요.</ServiceTypo>
        <Partners />
      </Wrap>
    </Container>
  );
};
