import { PartnerStart, Service } from "../Common";
import Image from "next/image";
import {
  HorizontalBarDotIcon,
  RightArrowDot,
  DownArrowDot,
  LeftArrowDot,
  Gen1,
  Gen2,
  Gen3,
  Gen4,
} from "components/Icons";
import {
  Wrapper,
  Wrap,
  MainTypo,
  SubTypo,
  WorkWrap,
  WorkDiv,
  IconDiv,
  ServiceTypo,
  Container,
  StorybookWrap,
  Storybook,
  StorybookMainTypo,
  StorybookSubTypo,
  ImageDiv,
  InfoDiv,
} from "./Styles";
import { Box } from "@mui/material";
import imageLoader from "utils/imageLoader";

export const GeneralConstruction = () => {
  return (
    <Wrapper>
      <Container sx={{ backgroundColor: "#FBFAFF" }} data-test="genCon">
        <Wrap>
          <div>
            <MainTypo>
              <span>종합건설사 파트너</span>는<br /> 어떤 일을 하나요?
            </MainTypo>
            <SubTypo>
              빌더허브 솔루션 기술로 3D모델링을 수행한 프로젝트 중<br />
              입찰 서비스를 통해 원하는 프로젝트에 참여할 수 있어요.
            </SubTypo>
          </div>
          <WorkWrap>
            <WorkDiv
              gridAutoRows={`1fr 30px 1fr`}
              gridTemplateAreas={`"first1 arr1 first2"
              " . . arr2"
             "second1 arr3 second2"`}
              alignItems={"center"}
              justifyItems={"center"}
            >
              <IconDiv sx={{ gridArea: "first1" }}>
                <Gen1 />
                <span>프로젝트 입찰</span>
              </IconDiv>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  paddingBottom: "32px",
                }}
              >
                <HorizontalBarDotIcon />
              </Box>
              <Box
                sx={{
                  gridArea: "arr1",
                  display: { xs: "flex", md: "none" },
                  paddingBottom: "32px",
                }}
              >
                <RightArrowDot />
              </Box>
              <IconDiv sx={{ gridArea: "first2" }}>
                <Gen2 />
                <span>정보 확인</span>
              </IconDiv>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  paddingBottom: "32px",
                }}
              >
                <HorizontalBarDotIcon />
              </Box>
              <Box
                sx={{ gridArea: "arr2", display: { xs: "flex", md: "none" } }}
              >
                <DownArrowDot />
              </Box>
              <IconDiv sx={{ gridArea: "second2" }}>
                <Gen3 />
                <span>서류제출</span>
              </IconDiv>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  paddingBottom: "32px",
                }}
              >
                <HorizontalBarDotIcon />
              </Box>
              <Box
                sx={{
                  gridArea: "arr3",
                  display: { xs: "flex", md: "none" },
                  paddingBottom: "32px",
                }}
              >
                <LeftArrowDot />
              </Box>
              <IconDiv sx={{ gridArea: "second1" }}>
                <Gen4 />
                <span>낙찰 & 계약</span>
              </IconDiv>
            </WorkDiv>
            <ServiceTypo>*서비스 준비중이에요.</ServiceTypo>
          </WorkWrap>
        </Wrap>
      </Container>
      <PartnerStart />
      <StorybookWrap>
        <Storybook>
          <InfoDiv>
            <StorybookMainTypo>건축 프로젝트 스토리북</StorybookMainTypo>
            <StorybookSubTypo>
              나의 작업물을 블로그 형식으로 업로드해
              <br />
              파트너들과 건축주들에게 홍보할 수 있어요.
              <br />
              빌더허브에서 진행한 프로젝트가 아니어도 등록이 가능해요!
            </StorybookSubTypo>
            <ServiceTypo
              sx={{ textAlign: "left", display: { xs: "none", md: "flex" } }}
            >
              *서비스 준비중이에요.
            </ServiceTypo>
          </InfoDiv>
          <ImageDiv>
            <Image
              src="/Main/GeneralConstruction/storybookNew.png"
              alt="generalConstructionStorybook"
              width={456}
              height={352}
              loader={imageLoader}
              //quality={100}}
            />
            <ServiceTypo sx={{ display: { xs: "flex", md: "none" } }}>
              *서비스 준비중이에요.
            </ServiceTypo>
          </ImageDiv>
        </Storybook>
      </StorybookWrap>
      <Service />
    </Wrapper>
  );
};
