import {
  Container,
  Wrap,
  MainTypo,
  SubTypo,
  StepCard,
  StepCardWrap,
  StepCardSubTypo,
  StepCardMainTypo,
} from "./Styles";
export const PartnerStart = () => {
  return (
    <Container>
      <Wrap>
        <div>
          <MainTypo>파트너 시작하기</MainTypo>
          <SubTypo>*추후 내용이 업데이트 될 예정이에요.</SubTypo>
        </div>
        <StepCardWrap>
          {stepCardInfo.map((item) => {
            return <StepCards key={item.id} {...item} />;
          })}
        </StepCardWrap>
      </Wrap>
    </Container>
  );
};

const stepCardInfo = [
  {
    id: 1,
    title: "회원가입",
    content: (
      <>
        빌더허브 파트너 등록 절차는
        <br />
        회원 가입 이후 진행됩니다.
      </>
    ),
  },
  {
    id: 2,
    title: "파트너 정보 입력",
    content: (
      <>
        파트너 유형을 선택하고
        <br />
        사업자 정보를 입력해 주세요.
      </>
    ),
  },
  {
    id: 3,
    title: "승인 대기",
    content: (
      <>
        서류 검토 후 승인 여부가 확정돼요.
        <br />
        *영업일 기준 최대 5일 소요.
      </>
    ),
  },
  {
    id: 4,
    title: "파트너 등록 완료",
    content: (
      <>
        승인이 확정되면
        <br />
        등록 완료 알림이 전송됩니다.
      </>
    ),
  },
];

const StepCards = (props: {
  id: number;
  title: string;
  content: React.ReactNode;
}) => {
  const { id, title, content } = props;
  return (
    <StepCard>
      <StepCardMainTypo>
        <span>{id.toString().padStart(2, "0")}</span>
        {title}
      </StepCardMainTypo>
      <StepCardSubTypo>{content}</StepCardSubTypo>
    </StepCard>
  );
};
