import { Box, Button, Typography, styled } from "@mui/material";
import { Container, Wrap, MainTypo } from "./Styles";
import { VerticalBarIcon } from "components";
import Image from "next/image";
import { colors, theme } from "@builderhub/mui-theme";
import { useRouter } from "next/router";
import imageLoader from "utils/imageLoader";

export const Service = () => {
  const router = useRouter();
  const goCustomer = () => {
    router.push(`${process.env.NEXT_PUBLIC_CUSTOMER_URL}`);
  };
  return (
    <Container>
      <Wrap>
        <div>
          <MainTypo>
            언제 어디서나 볼 수 있는
            <br /> 3D모델링이 필요하신가요?
          </MainTypo>
        </div>
        <ServiceDiv>
          <ServiceCard>
            <Image
              src="/Main/Architect/service1.png"
              alt="architectService1"
              width={96}
              height={96}
              loader={imageLoader}
              quality={70}
            />
            <ServiceCardMainTypo>빠르고 정확한 적산</ServiceCardMainTypo>
            <ServiceCardSubTypo>
              설계도서 업로드 및 검수완료 후에
              <br />
              BIM 모델과 수량 산출 결과를 제공
            </ServiceCardSubTypo>
          </ServiceCard>
          <VerticalBarIcon />
          <ServiceCard>
            <Image
              src="/Main/Architect/service2.png"
              alt="architectService2"
              width={96}
              height={96}
              loader={imageLoader}
              //quality={100}}
            />
            <ServiceCardMainTypo>철근 배근 정보 제공</ServiceCardMainTypo>
            <ServiceCardSubTypo>
              설계, 시공, 검측 과정에서 철근 배근 이해도를 높여
              <br />
              철근 누락이 없도록 현장 품질관리가 가능
            </ServiceCardSubTypo>
          </ServiceCard>
          <VerticalBarIcon />
          <ServiceCard>
            <Image
              src="/Main/Architect/service3.png"
              alt="architectService3"
              width={96}
              height={96}
              loader={imageLoader}
              //quality={100}}
            />
            <ServiceCardMainTypo>합리적인 가격</ServiceCardMainTypo>
            <ServiceCardSubTypo>
              중소형 건축 프로젝트를 대상으로
              <br />
              합리적인 가격으로 서비스를 제공
            </ServiceCardSubTypo>
          </ServiceCard>
        </ServiceDiv>
        <ServiceBtn
          disableRipple
          variant="contained"
          onClick={goCustomer}
          data-test="go-to-customer-main"
        >
          스마트 적산 이용하기
        </ServiceBtn>
      </Wrap>
    </Container>
  );
};

export const ServiceDiv = styled(Box)(() => ({
  maxWidth: 1096,
  width: "100%",
  minHeight: 196,
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: 80,
  textAlign: "center",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    svg: { display: "none" },
    gap: 32,
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    svg: { display: "none" },
    marginTop: 64,
  },
}));
export const ServiceCard = styled(Box)(() => ({
  maxWidth: 280,
  width: "100%",
  minHeight: 196,
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    img: {
      height: 80,
      width: 80,
    },
  },
}));

export const ServiceCardMainTypo = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 700,
  letterSpacing: -0.3,
  color: colors.gray._100,
  marginTop: 16,
  [theme.breakpoints.down("md")]: {
    FontFaceSet: 14,
    marginTop: 12,
  },
}));
export const ServiceCardSubTypo = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  letterSpacing: -0.3,
  color: colors.gray._40,
  marginTop: 16,
  [theme.breakpoints.down("md")]: {
    FontFaceSet: 12,
    marginTop: 12,
  },
}));

export const ServiceBtn = styled(Button)(() => ({
  marginTop: 80,
  fontSize: 16,
  fontWeight: 700,
  color: colors.white,
  background: colors.purple._60,
  boxShadow: "none",
  padding: "14px 32px",
  borderRadius: 8,
  letterSpacing: -0.3,
  height: 52,
  [theme.breakpoints.down("md")]: {
    marginTop: 64,
    fontSize: 16,
    fontWeight: 500,
    padding: "14px 20px",
    height: 48,
  },
  ":hover": {
    background: colors.purple._80,
    boxShadow: "none",
  },
}));
