import { colors, theme } from "@builderhub/mui-theme";
import { Box, Button, styled, Tab, Tabs, Typography } from "@mui/material";

export const Wrap = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minWidth: 360,
  paddingTop: 72,
}));

//Main

export const MainWrap = styled(Box)(() => ({
  maxWidth: 1200,
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));

export const Container = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  // maxWidth: 1400,
  width: "100%",
  minHeight: 556,
  alignItems: "center",
  position: "relative",
  paddingLeft: 118,
  paddingRight: 118,
  [theme.breakpoints.down("lg")]: {
    paddingLeft: 56,
    paddingRight: 56,
  },
  [theme.breakpoints.down("md")]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));
export const LeftDiv = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  whiteSpace: "nowrap",
  alignItems: "flex-start",
  height: "55%",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    alignItems: "center",
    height: "100%",
  },
}));
export const ContentDiv = styled(Box)(() => ({
  zIndex: 1,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  textAlign: "start",
  justifyContent: "flex-start",
  padding: 0,
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
    justifyContent: "space-between",
    padding: 64,
    alignItems: "center",
  },
}));
export const OpenTypo = styled(Typography)(() => ({
  fontWeight: 500,
  color: colors.purple._60,
  letterSpacing: -0.3,
  fontSize: 20,
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));
export const SubTypo = styled(Typography)(() => ({
  fontWeight: 500,
  color: colors.gray._100,
  letterSpacing: -0.3,
  marginTop: 16,
  fontSize: 36,
  [theme.breakpoints.down("md")]: {
    fontSize: 24,
  },
}));
export const LeftImage = styled(Box)(() => ({
  position: "absolute",
  left: 64,
  top: 64,
  img: {
    width: 136,
    heigh: 140,
  },
  [theme.breakpoints.down("md")]: {
    left: "35%",
    top: 35,
    img: {
      width: 70,
      height: 70,
    },
  },
}));
export const RightImage = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  img: {
    width: 456,
    heigh: 352,
  },
  position: "relative",
  [theme.breakpoints.down("md")]: {
    paddingTop: 54,
    img: {
      width: 288,
      height: 224,
    },
    position: "absolute",
  },
}));
export const RegBtn = styled(Button)(() => ({
  borderRadius: 8,
  background: colors.purple._60,
  color: colors.white,
  fontSize: 16,
  fontWeight: 500,
  letterSpacing: -0.3,
  marginTop: 48,
  width: "100%",
  maxWidth: 200,
  height: 52,
  padding: "14px 47.5px",
  [theme.breakpoints.down("md")]: {
    height: 48,
    width: 153,
    padding: "14px 24px",
  },
}));

// Tabs
export const TabBox = styled(Box)(() => ({
  width: "100%",
}));

export const TabWrap = styled(Box)(() => ({
  maxWidth: 1200,
  minWidth: "100%",
  // minWidth: 360,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  borderBottom: `2px solid ${colors.gray._20}`,
  position: "sticky",
  top: 72,
  background: colors.white,
  [theme.breakpoints.down("lg")]: {
    top: 52,
  },
  [theme.breakpoints.down("sm")]: {
    top: 48,
  },
}));
export const StyledTabs = styled(Tabs)(() => ({
  // maxWidth: 1200,
  // width: "100%",
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: colors.purple._60,
    borderRadius: `4px 4px 0px 0px`,
    height: 4,
  },
  "& .MuiTabs-indicatorSpan": {
    backgroundColor: "#635ee7",
  },
  button: {
    minHeight: 36,
    // maxWidth: 260,
    whiteSpace: "nowrap",
  },
}));
export const StyledTab = styled(Tab)(() => ({
  fontWeight: 400,
  letterSpacing: -0.3,
  background: colors.white,
  color: colors.gray._40,
  marginRight: 8,
  marginLeft: 8,
  padding: "14px 65.5px",
  // maxWidth: 260,
  // width: "100%",
  fontSize: 20,
  ":hover": {
    color: colors.purple._10,
  },
  "&.Mui-selected": {
    fontWeight: 500,
    color: colors.purple._60,
    background: colors.white,
  },
  [theme.breakpoints.down("lg")]: {
    padding: "14px 40px",
    fontSize: 16,
  },
  [theme.breakpoints.down("sm")]: {
    padding: "11px 20px",
    fontSize: 14,
    marginRight: 0,
    marginLeft: 0,
  },
}));
