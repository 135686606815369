import { colors, theme } from "@builderhub/mui-theme";
import { styled, Grid, Typography, Divider, Box, Button } from "@mui/material";

export const Wrap = styled(Grid)(() => ({
  width: "100%",
  minWidth: 360,
  display: "flex",
  justifyContent: "center",
  color: colors.gray._60,
  backgroundColor: "#FAFAFC",
  padding: "42px 120px",
  borderTop: `1px solid ${colors.gray._20}`,
  [theme.breakpoints.down("lg")]: {
    padding: 0,
  },
}));

export const Container = styled(`div`)(() => ({
  width: "100%",
  maxWidth: 1200,
  padding: "0px 16px ",
  display: "flex",
  flexDirection: "column",
  gap: 32,
  [theme.breakpoints.down("lg")]: {
    padding: "32px 40px ",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "24px 20px ",
    gap: 18,
  },
}));
export const LogoGrid = styled(Grid)(() => ({
  alignSelf: "start",
  display: "flex",
  alignItems: "center",
}));

export const RowWrap = styled(Grid)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  lineHeight: "normal",
  // padding: "30px 0 0",
  // [theme.breakpoints.down("sm")]: {
  //   padding: "25px 0 0",
  // },
  // [theme.breakpoints.down("xs")]: {
  //   padding: "10px 0 0",
  // },
}));

export const RowWrapBordered = styled(Grid)(() => ({
  width: "100%",
  padding: "30px 0",
}));

export const ButtonWrap = styled(Box)(() => ({
  minWidth: 132,
  width: "fit-content",
  color: "white",
  fontSize: 16,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingRight: 12,
  gap: 24,
  [theme.breakpoints.down("lg")]: {
    gap: 20,
  },
  [theme.breakpoints.down("sm")]: {
    gap: 8,
  },
}));

export const FooterButton = styled(Button)(() => ({
  cursor: "pointer",
  color: colors.gray._60,
  fontSize: 16,
  whiteSpace: "nowrap",
  [theme.breakpoints.down("lg")]: {
    fontSize: 14,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 10,
    padding: 0,
    minWidth: 0,
  },
}));

export const IconWrap = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 16,
  svg: {
    width: 64,
    height: 64,
    cursor: "pointer",
  },
  button: {
    padding: 0,
  },
  [theme.breakpoints.down("lg")]: {
    gap: 12,
    justifyContent: "flex-start",
    svg: {
      width: 48,
      height: 48,
    },
  },
  [theme.breakpoints.down("sm")]: {
    svg: {
      width: 36,
      height: 36,
    },
  },
}));

export const InfoTypo = styled(Typography)(() => ({
  textAlign: "left",
  fontSize: 12,
  [theme.breakpoints.down("sm")]: {
    fontSize: 8,
  },
}));

export const FooterTypo = styled(Typography)(() => ({
  fontSize: 12,
  textAlign: "left",
  whiteSpace: "nowrap",
  [theme.breakpoints.down("sm")]: {
    fontSize: 8,
    // textAlign: "center",
  },
}));

export const FooterGrid = styled(Box)(() => ({
  display: "flex",
  justifyContent: "end",
  fontWeight: 700,
  [theme.breakpoints.down("sm")]: {
    // justifyContent: "center",
    // marginTop: 4,
  },
}));

export const DividerBar = styled(Divider)(() => ({
  border: 1,
  color: "#7D7B88",
  borderRadius: 1,
  display: "flex",
  alignContent: "center",
  height: 16,
  alignSelf: "center",
  width: 2,
  background: colors.gray._60,
  [theme.breakpoints.down("sm")]: {
    height: 12,
    width: 1,
  },
}));

export const NoticeWrap = styled(ButtonWrap)(() => ({
  gap: 32,
  hr: { marginLeft: 0, marginRight: 0 },
  button: {
    padding: 0,
    fontSize: 16,
  },
  [theme.breakpoints.down("sm")]: {
    gap: 16,
    button: { fontSize: 12 },
  },
}));
export const WebBtnBox = styled(Box)(() => ({
  display: "contents",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));
export const MobileBtnBox = styled(Box)(() => ({
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
  },
}));

export const FooterBtn = styled(Button)(() => ({
  color: colors.gray._60,
  fontSize: 16,
  fontWeight: 700,
  letterSpacing: -0.3,
  padding: "4px 12px",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    fontWeight: 700,
    padding: 0,
    minWidth: 0,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 10,
  },
}));
export const FooterDivider = styled(Divider)(() => ({
  display: "flex",
  background: colors.gray._60,
  height: 16,
  width: 1,
  marginLeft: 8,
  marginRight: 8,
  borderRadius: 10,
  [theme.breakpoints.down("md")]: {
    height: 12,
    marginLeft: 12,
    marginRight: 12,
  },
  [theme.breakpoints.down("sm")]: {
    height: 8,
    // marginTop: 3,
  },
}));
