import { InferGetStaticPropsType } from "next";
import Head from "next/head";
import { PartnerLayout, Partners } from "views/Main/PartnerLayout";
import { Architect } from "views/Main/Tabs";

const Main = () => {
  return (
    <>
      <Head>
        <title>빌더허브 파트너스</title>
      </Head>
      <PartnerLayout partner={Partners.architect}>
        <Architect />
      </PartnerLayout>
    </>
  );
};

export default Main;
