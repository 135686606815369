import {
  Container,
  Wrap,
  MainTypo,
  SubTypo,
  ServiceTypo,
  CardWrap,
  Card,
  CardMainTypo,
  CardSubTypo,
  ImageDiv,
} from "./Styles";
import Image from "next/image";
import imageLoader from "utils/imageLoader";

export const Modeler = () => {
  return (
    <Container data-test="modeler">
      <Wrap>
        <div>
          <MainTypo>
            <span>모델링 전문가 파트너</span>는<br /> 어떤 일을 하나요?
          </MainTypo>
          <SubTypo>
            입찰시스템을 통해 진행되고 있는 프로젝트의
            <br />
            3D 모델링과 골조, 마감의 물량산출을 진행해요.
          </SubTypo>
        </div>
        <CardWrap>
          <Card>
            <ImageDiv>
              <Image
                src="/Main/Modeler/modelLeft.png"
                alt="modelerLeft"
                width={100}
                height={100}
                loader={imageLoader}
                //quality={100}}
              />
            </ImageDiv>
            <CardMainTypo>프로젝트 수주</CardMainTypo>
            <CardSubTypo>
              무료로 제공되는 빌더허브 모델러로
              <br />
              수주받은 프로젝트를 작업해요.
            </CardSubTypo>
          </Card>
          <Card>
            <ImageDiv>
              <Image
                src="/Main/Modeler/modelRight.png"
                alt="modelerRight"
                width={100}
                height={100}
                loader={imageLoader}
                //quality={100}}
              />
            </ImageDiv>
            <CardMainTypo>모델링과 물량 산출</CardMainTypo>
            <CardSubTypo>
              빌더허브 솔루션으로 골조공사와
              <br />
              마감공사 등에 필요한 작업을 진행해요.
            </CardSubTypo>
          </Card>
        </CardWrap>
        <ServiceTypo>*서비스 준비중이에요.</ServiceTypo>
      </Wrap>
    </Container>
  );
};
