import React from "react";
import { Grid, Divider, IconButton } from "@mui/material";
import {
  Wrap,
  Container,
  LogoGrid,
  ButtonWrap,
  FooterButton,
  IconWrap,
  RowWrap,
  InfoTypo,
  FooterTypo,
  FooterGrid,
  DividerBar,
  FooterBtn,
  FooterDivider,
  MobileBtnBox,
  NoticeWrap,
  WebBtnBox,
} from "./styles";
import {
  BuilderhubLogo,
  BuilderhubLogoIcon,
  Changsoft,
  Copy,
  Instagram,
  Youtube,
} from "components";
import { lang } from "moment";
interface PropType {
  handleClick(val: string, auth?: boolean): void;
  authenticated: boolean;
}
export const Footer = (props: PropType) => {
  const { handleClick, authenticated } = props;
  return (
    <Wrap container>
      <Container>
        <LogoGrid item>
          <BuilderhubLogoIcon style={{ marginRight: 8 }} />
          <BuilderhubLogo />
        </LogoGrid>
        <RowWrap>
          <NoticeWrap>
            <WebBtnBox>
              <FooterBtn onClick={() => handleClick("notice")} disableRipple>
                공지사항
              </FooterBtn>
              <FooterDivider orientation="vertical" variant="middle" flexItem />
              <FooterBtn
                onClick={() => handleClick("inquiry", authenticated)}
                disableRipple
              >
                1:1 온라인 문의
              </FooterBtn>
            </WebBtnBox>
            <MobileBtnBox>
              <FooterBtn onClick={() => handleClick("notice")} disableRipple>
                고객센터
              </FooterBtn>
            </MobileBtnBox>
            <FooterDivider orientation="vertical" variant="middle" flexItem />
            <FooterBtn
              disableRipple
              onClick={() => handleClick("notification")}
            >
              이용 전 안내사항
            </FooterBtn>
          </NoticeWrap>
        </RowWrap>
        <RowWrap container item spacing={2}>
          <Grid item xs={12} md={6}>
            <InfoTypo>
              상호명 : (주)창소프트아이앤아이 ㅣ 대표자명 : 김은석
              <br />
              사업장소재지 : 서울특별시 강남구 테헤란로103길 8-7, 2~4F (삼성동)
              <br />
              사업자등록번호: 220-87-61198 ㅣ 전화번호: 02-563-1328
            </InfoTypo>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: { xs: "start", md: "end" },
            }}
          >
            <IconWrap>
              <IconButton onClick={() => handleClick("homepage")}>
                <Changsoft />
              </IconButton>
              <IconButton onClick={() => handleClick("instagram")}>
                <Instagram />
              </IconButton>
              <IconButton onClick={() => handleClick("copy")}>
                <Copy />
              </IconButton>
              <IconButton onClick={() => handleClick("youtube")}>
                <Youtube />
              </IconButton>
            </IconWrap>
          </Grid>
        </RowWrap>
        <Divider sx={{ background: "#DBDBDB" }} />
        <RowWrap>
          <FooterTypo>{`ⓒ${new Date().getFullYear()} ChangSoft I&I. All Rights Reserved.`}</FooterTypo>
          <FooterGrid>
            <ButtonWrap>
              <FooterButton onClick={() => handleClick("type1")}>
                이용약관
              </FooterButton>
              <DividerBar orientation="vertical" variant="middle" flexItem />
              <FooterButton onClick={() => handleClick("type2")}>
                개인정보처리방침
              </FooterButton>
            </ButtonWrap>
          </FooterGrid>
        </RowWrap>
      </Container>
    </Wrap>
  );
};
