import { colors, theme } from "@builderhub/mui-theme";
import { Box, Typography, styled } from "@mui/material";

export const Container = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  minWidth: 360,
  minHeight: 666,
  background: colors.white,
  flexDirection: "column",
  alignItems: "center",
}));
export const Wrap = styled(Box)(() => ({
  maxWidth: 1200,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "144px 0px",
  [theme.breakpoints.down("md")]: {
    padding: "80px 0px",
  },
}));

export const MainTypo = styled(Typography)(() => ({
  fontSize: 28,
  fontWeight: 700,
  color: colors.gray._100,
  letterSpacing: -0.3,
  whiteSpace: "nowrap",
  textAlign: "center",
  br: {
    display: "none",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 20,
  },
  [theme.breakpoints.down("md")]: {
    br: {
      display: "block",
    },
  },
}));

export const SubTypo = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 400,
  color: colors.purple._60,
  letterSpacing: -0.3,
  whiteSpace: "nowrap",
  textAlign: "center",
  marginTop: 8,
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
  },
}));

export const StepCardWrap = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  maxWidth: 1200,
  width: "100%",
  marginTop: 64,
  gap: 16,
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 576,
    gap: 24,
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: 32,
    gap: 16,
  },
}));
export const StepCard = styled(Box)(() => ({
  background: colors.white,
  border: `1px solid ${colors.gray._20}`,
  borderRadius: 12,
  maxWidth: 272,
  maxHeight: 176,
  width: "100%",
  height: "100%",
  padding: "42px 24px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "start",
  [theme.breakpoints.down("md")]: {
    maxHeight: 220,
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: 240,
    minWidth: 228,
    maxHeight: 220,
  },
}));
export const StepCardMainTypo = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 700,
  letterSpacing: -0.3,
  color: colors.gray._100,
  span: {
    display: "inline-block",
    marginRight: 12,
    height: 32,
    borderBottom: `1px solid ${colors.gray._100}`,
  },
  [theme.breakpoints.down("md")]: {
    span: {
      marginRight: 9.5,
      height: 24,
    },
    fontSize: 14,
  },
}));
export const StepCardSubTypo = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: colors.gray._40,
  letterSpacing: -0.3,
  marginTop: 24,
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    marginTop: 16,
  },
}));
