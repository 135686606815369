import { colors, theme } from "@builderhub/mui-theme";
import { Box, Typography, styled } from "@mui/material";

export const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));
export const Wrap = styled(Box)(() => ({
  maxWidth: 1200,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "128px 0px",
  [theme.breakpoints.down("md")]: {
    padding: "80px 60px",
  },
}));
export const MainTypo = styled(Typography)(() => ({
  fontSize: 28,
  fontWeight: 400,
  color: colors.gray._60,
  letterSpacing: -0.3,
  whiteSpace: "nowrap",
  span: {
    fontWeight: 700,
    color: colors.purple._60,
  },
  br: {
    display: "none",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
    fontSize: 20,
    br: {
      display: "flex",
    },
  },
}));

export const SubTypo = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: colors.gray._60,
  letterSpacing: -0.3,
  whiteSpace: "nowrap",
  textAlign: "center",
  marginTop: 16,
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));
export const ServiceTypo = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: -0.3,
  whiteSpace: "nowrap",
  color: colors.purple._60,
  textAlign: "center",
  marginTop: 32,
  [theme.breakpoints.down("md")]: {
    height: 14,
    fontSize: 10,
  },
}));

export const Container = styled(Box)(() => ({
  width: "100%",
  minHeight: 578,
  minWidth: 360,
  display: "flex",
  justifyContent: "center",
  background: colors.white,
  flexDirection: "column",
  alignItems: "center",
}));
export const WorkWrap = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: 620,
  width: "100%",
  minHeight: 132,
  padding: "0px 16px",
  fontSize: 16,
  marginTop: 46,
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 32,
    padding: 0,
  },
}));
export const WorkDiv = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  minWidth: 322,
  justifyContent: "space-between",
  fontWeight: 500,
  textAlign: "center",
  span: { marginTop: 16 },
  [theme.breakpoints.down("md")]: {
    span: { marginTop: 12 },
    display: "grid",
    maxWidth: 248,
    justifyContent: "center",
  },
}));
export const IconDiv = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minWidth: 108,
  minHeight: 108,
  svg: {
    // marginTop: 16,
    // marginBottom: 16,
    // height: 20,
  },
  [theme.breakpoints.down("md")]: {
    minHeight: 62,
    svg: {
      marginTop: 12,
      marginBottom: 12,
    },
    img: {
      width: 80,
      height: 80,
    },
  },
}));
export const StorybookWrap = styled(Box)(() => ({
  maxWidth: 1200,
  width: "100%",
  display: "flex",
  alignItems: "center",
  padding: "0px 118px",
  justifyContent: "center",
  flexDirection: "column",
  minHeight: 640,
  [theme.breakpoints.down("md")]: {
    padding: "80px 16px",
    minHeight: 360,
  },
}));
export const Storybook = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  gap: 32,
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    padding: "80px 16px",
    minHeight: 360,
  },
}));
export const StorybookMainTypo = styled(Typography)(() => ({
  fontSize: 28,
  fontWeight: 700,
  color: colors.gray._100,
  letterSpacing: -0.3,
  [theme.breakpoints.down("md")]: {
    fontSize: 24,
  },
}));

export const StorybookSubTypo = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: colors.gray._60,
  letterSpacing: -0.3,
  marginTop: 16,
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    fontWeight: 500,
  },
}));
export const StorybookService = styled(ServiceTypo)(() => ({
  textAlign: "start",
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
}));

export const InfoDiv = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  // alignItems: "center",
  textAlign: `start`,
  [theme.breakpoints.down("lg")]: {
    alignItems: "center",
    textAlign: "center",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
}));
export const ImageDiv = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    img: {
      width: 248,
      height: 176,
    },
  },
}));
